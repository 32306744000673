.ps-tags-input .rti--container {
    --rti-bg: theme('colors.dark.dark1');
    --rti-border: theme('colors.dark.dark1');
    --rti-main: theme('colors.dark.dark1');
    --rti-radius: 2px;
    --rti-tag: theme('colors.dark.dark2');
    --rti-tag-remove: theme('colors.white');
    --rti-tag-padding: 3px 0 4px 8px;
    border: none;
    padding: 4px 8px;
}

.ps-tags-input .rti--tag button {
    color: theme('colors.gray.normal');
}

.ps-tags-input .rti--tag {
    border: 2px solid #353535;
}

.ps-tags-input .rti--tag :focus-visible {
    outline: none;
}

.ps-tags-input .rti--input {
    background: var(--rti-bg);
    padding: 5px 4px 6px;
}

.ps-tags-input .rti--input:focus {
    box-shadow: none;
    border: none;
    outline: none;
}

.ps-tags-input .rti--container:focus-within {
    box-shadow: none;
}
