.table-h1 {
    @apply typography-big-accent text-white flex min-h-[68px] pt-[12px] pl-[15px]
}

.table-h2 {
    @apply typography-small-accent text-white flex flex-col min-h-[68px] pt-[14px] pl-[15px]
}

.table-h3 {
    @apply typography-small text-gray-normal flex py-[9.5px] pl-[15px]
}

.table-body-line {
    @apply divide-x divide-gray-strokeLight
}

.table-body-cell {
    @apply py-[20px] pl-[15px]
}

.sort-icon {
    @apply flex justify-center items-center h-[17px] w-[22px] group-hover:opacity-100
}
