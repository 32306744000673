@import "scrollbar.css";

@import-normalize;

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: icon;
    src: url("assets/fonts/icon/icon.woff"), url("assets/fonts/icon/icon.woff2");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

*,
*:before,
*:after {
    @apply box-border;
    -webkit-tap-highlight-color: transparent;
}

html {
    @apply w-full h-full bg-dark-default text-white;
}

#root {
    @apply h-full;
    --topBarHeight: 48px;
    --bottomBarHeight: 56px;
    --tabHeaderHeight: 40px;
}

body {
    @apply h-full;
    min-width: 1000px;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'ss03' on;
}

code {
    font-family: "Source Code Pro", monospace;
}

.js-focus-visible :focus {
    @apply outline outline-2 outline-electro;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

.js-focus-visible :focus-visible {
    @apply outline outline-2 outline-electro;
}

.scrolling-touch {
    -webkit-overflow-scrolling: touch;
}

#headlessui-portal-root {
    position: fixed;
    z-index: 99;
    inset: 0;
}

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    @apply bg-electro;
}

#nprogress .spinner {
    position: fixed;
    z-index: 1031;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
}

#nprogress .spinner-icon {
    width: 24px;
    height: 24px;
    animation: nprogress-spinner 400ms linear infinite;
    border: solid 2px transparent;
    border-radius: 50%;
    @apply border-t-electro border-l-electro;
}

.nprogress-custom-parent {
    position: relative;
    overflow: hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

@layer utilities {
    .typography-big-accent {
        @apply text-big font-medium leading-t-big-accent tracking-wide
    }

    .typography-small-accent {
        @apply text-small font-medium leading-t-small-accent tracking-wide
    }

    .typography-small {
        @apply text-small font-normal leading-t-small-accent tracking-t-small
    }

    .typography-normal {
        @apply text-normal font-normal leading-t-normal tracking-wide
    }

    .typography-normal-accent {
        @apply text-normal font-medium leading-t-normal tracking-t-small
    }
}
